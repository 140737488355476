import "styles/pages/single-post.scss";

import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import PostBanner from "components/PostBanner";
import TitleWithLine from "components/TitleWithLine";
import NewsTile from "components/NewsTile";

const SingleBlog = ({ pageContext, data }) => {
  const blogPosts = data.blogPagePosts.nodes;
  const blog = pageContext.post;

  return (
    <Layout>
      <Seo
        title={pageContext?.title}
        description={
          blog?.acfBlogNews?.seoMetaDescription
            ? blog?.acfBlogNews?.seoMetaDescription
            : pageContext?.seo?.description
        }
      />
      <PostBanner
        title={blog.title}
        className="custom-post"
        date={blog.date}
        parentLink="/blog/"
      />
      <section className="post">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <img
                src={blog.featuredImage?.node.sourceUrl}
                alt=""
                className="post__img"
              />
            </div>
            <div className="col-lg-6">
              <div
                className="post__content"
                dangerouslySetInnerHTML={{ __html: blog.content }}
              />
              <div className="post__nav">
                {pageContext?.prev && (
                  <Link
                    to={pageContext?.prev?.uri}
                    className="btn btn--white btn--reversed"
                  >
                    Previous post
                  </Link>
                )}
                {pageContext?.next && (
                  <Link to={pageContext?.next?.uri} className="btn btn--white">
                    Next post
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {blog?.author?.node?.acfAuthor?.authorName && (
        <section className="post-author">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-2">
                {blog?.author?.node?.acfAuthor?.authorImage?.localFile
                  ?.publicURL && (
                  <img
                    className="img-fluid post-author__image"
                    src={
                      blog?.author?.node?.acfAuthor?.authorImage?.localFile
                        ?.publicURL
                    }
                    alt=""
                  />
                )}
                <div className="post-author__social">
                  {blog?.author?.node?.acfAuthor?.authorLinkedin && (
                    <a
                      href={blog?.author?.node?.acfAuthor?.authorLinkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          require("../assets/icons/post-author-linkedin.svg")
                            .default
                        }
                        alt=""
                      />
                    </a>
                  )}
                  {blog?.author?.node?.acfAuthor?.authorEmail && (
                    <a
                      href={`mailto:${blog?.author?.node?.acfAuthor?.authorEmail}`}
                    >
                      <img
                        src={
                          require("../assets/icons/post-author-mail.svg")
                            .default
                        }
                        alt=""
                      />
                    </a>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <h4>About author</h4>
                <h2>{blog?.author?.node?.acfAuthor?.authorName}</h2>
                <h5>{blog?.author?.node?.acfAuthor?.authorPosition}</h5>
                {blog?.author?.node?.acfAuthor?.authorDescription && (
                  <p>{blog?.author?.node?.acfAuthor?.authorDescription}</p>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      {blogPosts.length > 0 && (
        <section className="featured-news">
          <div className="container">
            <TitleWithLine variantX="left" variantY="bottom">
              Featured blog posts
            </TitleWithLine>
            <div className="row section-news__row">
              {blogPosts?.map((nodes, index) => {
                const item = nodes;
                return (
                  <div
                    className="col-lg-4 col-md-6 section-news__tile"
                    key={index}
                  >
                    <NewsTile
                      link={item.uri}
                      image={item.featuredImage?.node.sourceUrl}
                      date={item.date}
                      title={item.title}
                      desc={item.blogPosts.shortDescription}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    blogPagePosts: allWpBlogPost(limit: 3) {
      nodes {
        content
        date(formatString: "D MMMM YYYY")
        featuredImage {
          node {
            sourceUrl
          }
        }
        slug
        seo {
          metaDesc
          title
        }
        title
        uri
        blogPosts {
          shortDescription
        }
        language {
          name
          locale
          code
          slug
        }
      }
    }
  }
`;

export default SingleBlog;
